@import './main.scss';

.navbar-main {
    background-color: black;
   
}
.nav-logo {
    width: 100%;
    transition: all .6s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
}



.logo-box {
    width: 200px;
}

.social-icons {
   
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 10px;
        color: #fff;
        font-size: 220px;
        line-height: 250px;
        vertical-align: middle;
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        box-shadow: 0px 3px 10px rgba(0,0,0,.25);
      
}


.linkage {
   transition: all .5s ease-in-out !important;
   &:hover {
       transform: scale(1.2);
   }
}

.nav-logo {
    width: 150px;
}


.navbar-toggler {
    background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}