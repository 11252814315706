.social-contact {
    margin-top: 9px;
    font-size: 1.5em;
    color: black;
    &:hover {
        color:#b2ab4f;
    }
}

a:active {
    color:black;
}