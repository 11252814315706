.age-div {
    background-color: black;
    background-image: url('../img/weed3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:'100%';
    height: 200vh;
}

.a-age {
    text-decoration: none;
    color:white;
}

body {
    padding: 0;
    margin :0;
    background-color: black;
}
