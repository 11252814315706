$secondary: #64AF9A;
$main: radial-gradient(ellipse farthest-corner at right bottom,
 #FEDB37 0%, 
 #FDB931 8%, 
 #9f7928 30%, 
 #8A6E2F 40%, 
 transparent 80%),
radial-gradient(ellipse farthest-corner at left top,
 #FFFFFF 0%, 
 #FFFFAC 8%,
#D1B464 25%,
 #5d4a1f 62.5%,
  #5d4a1f 100%);

.menu-div {
    background-image: url('../img/weed3.png');
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}

.nav-link {
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 15%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 50%, #D1B464 70%, #5d4a1f 62.5%, #5d4a1f 100%);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  font-size: 1.2em;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  
};

.navbar-toggler {
  border-color: rgb(255,102,203);
} 

.text-adjust{
  text-align: center;
  background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 50%, #D1B464 70%, #5d4a1f 62.5%, #5d4a1f 100%);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  font-size: 1.1em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

a {
  text-decoration: none !important;
  color:'black';
}
